"use client";
import { toggleVideoModalOpen } from "@/redux/features/videoModalSlice";
import { useAppDispatch } from "@/redux/hooks";
import React, { useEffect, useState } from "react";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import gsap from "gsap";
import SplitType from "split-type";

const BannerSection = () => {
  const dispatch = useAppDispatch();
  const openVideoModal = () => {
    dispatch(toggleVideoModalOpen());
  };
  const [swiper, setSwiper] = useState<any>(null);

  useEffect(() => {
    if (swiper) {
      swiper.on("slideChange", () => {
        const currentSlide = swiper.slides[swiper.activeIndex];
        const textsToAnimate = currentSlide.querySelectorAll(".rv-text-anime");
        textsToAnimate.forEach((textToAnimate: HTMLElement) => {
          const animate = new SplitType(textToAnimate, {
            types: "words,chars",
          });
          gsap.from(animate.chars, {
            opacity: 0,
            x: 100,
            duration: 1.1,
            stagger: { amount: 0.9 },
          });
        });
      });
    }
  }, [swiper]);

  return (
    <section className="rv-20-banner_section">
      <Swiper
        autoplay={{ delay: 5000 }}
        loop={true}
        navigation={{
          nextEl: ".rv-20-banner_slide_button_next",
          prevEl: ".rv-20-banner_slide_button_prev",
        }}
        modules={[Navigation, Autoplay]}
        onSwiper={(swiper) => setSwiper(swiper)}
      >
        <SwiperSlide className="rv-20-banner_slide" 
          style={{
            height: '100%',
            width: '100%',
            backgroundImage: 'url("https://i.imgur.com/nJPIYGC.png")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
          }}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-10 col-md-9 col-lg-8 col-xl-7">
                <div className="rv-20-banner_content">
                  <span className="rv-20-banner_content_sub_heading rv-text-anime d-flex" style={{ fontSize: '1.2em' }}>
                    GOODCHEM
                  </span>
                  <h1 className="rv-20-banner_content_heading rv-text-anime">
                    Proteins & Fibers
                  </h1>
                  <div className="rv-20-banner_button_area">
                    <a href="#" className="rv-20-banner_content_btn">
                      Explore More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide className="rv-20-banner_slide" 
          style={{
            height: '100%',
            width: '100%',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: 'url("https://i.imgur.com/3o3znyU.png")',
            backgroundRepeat: 'no-repeat'
          }}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-10 col-md-9 col-lg-8 col-xl-7">
                <div className="rv-20-banner_content">
                  <span className="rv-20-banner_content_sub_heading rv-text-anime" style={{ fontSize: '1.2em' }}>
                    GOODCHEM
                  </span>
                  <h1 className="rv-20-banner_content_heading rv-text-anime">
                    Pet Food & Animal Feed Ingredients
                  </h1>
                  <div className="rv-20-banner_button_area">
                    <a href="#" className="rv-20-banner_content_btn">
                      Explore More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide className="rv-20-banner_slide" 
          style={{
            height: '100%',
            width: '100%',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: 'url("https://i.imgur.com/zTiNwF5.png")',
            backgroundRepeat: 'no-repeat'
          }}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-10 col-md-9 col-lg-8 col-xl-7">
                <div className="rv-20-banner_content">
                  <span className="rv-20-banner_content_sub_heading rv-text-anime" style={{ fontSize: '1.2em' }}>
                    GOODCHEM
                  </span>
                  <h1 className="rv-20-banner_content_heading rv-text-anime">
                    Human Nutrition
                  </h1>
                  <div className="rv-20-banner_button_area">
                    <a href="#" className="rv-20-banner_content_btn">
                      Explore More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide className="rv-20-banner_slide" 
          style={{
            height: '100%',
            width: '100%',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: 'url("https://i.imgur.com/D3R8yKt.png")',
            backgroundRepeat: 'no-repeat'
          }}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-10 col-md-9 col-lg-8 col-xl-7">
                <div className="rv-20-banner_content">
                  <span className="rv-20-banner_content_sub_heading rv-text-anime" style={{ fontSize: '1.2em' }}>
                    GOODCHEM
                  </span>
                  <h1 className="rv-20-banner_content_heading rv-text-anime">
                    Acidulants
                  </h1>
                  <div className="rv-20-banner_button_area">
                    <a href="#" className="rv-20-banner_content_btn">
                      Explore More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide className="rv-20-banner_slide" 
          style={{
            height: '100%',
            width: '100%',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: 'url("https://i.imgur.com/FiuomCS.png")',
            backgroundRepeat: 'no-repeat'
          }}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-10 col-md-9 col-lg-8 col-xl-7">
                <div className="rv-20-banner_content">
                  <span className="rv-20-banner_content_sub_heading rv-text-anime" style={{ fontSize: '1.2em' }}>
                    GOODCHEM
                  </span>
                  <h1 className="rv-20-banner_content_heading rv-text-anime">
                    Hydrocolloids
                  </h1>
                  <div className="rv-20-banner_button_area">
                    <a href="#" className="rv-20-banner_content_btn">
                      Explore More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide className="rv-20-banner_slide" 
          style={{
            height: '100%',
            width: '100%',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: 'url("https://i.imgur.com/rQPVg7O.png")',
            backgroundRepeat: 'no-repeat'
          }}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-10 col-md-9 col-lg-8 col-xl-7">
                <div className="rv-20-banner_content">
                  <span className="rv-20-banner_content_sub_heading rv-text-anime" style={{ fontSize: '1.2em' }}>
                    GOODCHEM
                  </span>
                  <h1 className="rv-20-banner_content_heading rv-text-anime">
                    Sweeteners
                  </h1>
                  <div className="rv-20-banner_button_area">
                    <a href="#" className="rv-20-banner_content_btn">
                      Explore More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <div className="rv-20-banner_slide_buttons">
        <div className="rv-20-banner_slide_button_prev">
          <i className="bi bi-arrow-left-short" />
        </div>
        <div className="rv-20-banner_slide_button_next">
          <i className="bi bi-arrow-right-short" />
        </div>
      </div>
    </section>
  );
};

export default BannerSection;
